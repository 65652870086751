<template>
  <div class="section1">
    <div>
<!--<svg class="logo absolute" version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 750.6 437.7" style="enable-background:new 0 0 750.6 437.7;" xml:space="preserve">
	<title>logo</title>  
		<path class="st0 tt2" d="M280.6,432.6c6.6-2.4,10-6.3,10-11.6v-2.6h-7.7v-3.9h13.4l-1.1-1.6c-0.1-0.1-0.2-0.2-0.2-0.3l4.9-0.9l-0.2-2
			h-19.5v3.2c-0.1,4.3-0.5,8.6-1.2,12.8c-0.6,3.4-1.5,6.7-2.8,9.9l-4.6-2.4c1.2-2.9,2-5.8,2.6-8.9c0.7-4,1-8.2,1-12.3v-20.2
			c1.6,0,3.2,0,4.7,0c5.1,0,10.1-0.1,14.8-0.3c6.2-0.3,12-0.6,17.5-1.1l1.5,3.7c-5.4,0.3-10.7,0.5-15.9,0.7
			c-5.6,0.2-11.3,0.3-16.7,0.4l-1,0v3.4h32.2v10.9h-12l0.9,1.5c0.2,0.3,0.4,0.7,0.5,1.1c0.2,0.5,0.4,1,0.7,1.6l0.3,0.6h11.9v3.9
			h-19.1v3.8h16.3c0,1.7-0.1,3.3-0.2,4.7c-0.1,1.5-0.3,3-0.6,4.4c-0.9,3.4-3,5-6.5,5.1c-1.2,0-2.4,0-3.5-0.1l-2.7-0.2l-0.5-3.8
			c0.6,0.1,1.3,0.1,1.9,0.2c1.1,0.1,2,0.1,3.1,0.1l0.1,0c0.7,0,1.5-0.2,2.1-0.7l0.1-0.1c0.6-0.5,1-1.3,1.2-2c0.1-0.5,0.2-1,0.2-1.5
			l0-2.1h-11.3l-0.2,0.7c-0.8,2.2-2.1,4.2-4,5.7c-2.3,1.8-4.8,3.2-7.6,4.2L280.6,432.6z M280.1,406h27.3v-3.6h-27.3V406z
			 M550.9,432.6c6.6-2.4,10-6.3,10-11.6v-2.6h-7.7v-3.9h13.5c0,0-1.4-1.8-1.4-1.9l4.9-0.9l-0.2-2h-19.5v3.2
			c-0.1,4.3-0.5,8.6-1.2,12.8c-0.6,3.4-1.5,6.7-2.8,9.9l-4.5-2.4c1.2-2.8,2-5.8,2.6-8.9c0.7-4.1,1-8.2,1-12.3v-20.2
			c1.6,0,3.2,0,4.7,0c5.1,0,10.1-0.1,14.8-0.3c6.2-0.3,12.1-0.6,17.5-1.1l1.5,3.7c-5.4,0.3-10.7,0.5-15.9,0.7
			c-5.6,0.2-11.3,0.3-16.8,0.4l-1,0v3.4h32.2v10.9h-12l0.9,1.5c0.2,0.3,0.4,0.7,0.5,1.1c0.2,0.5,0.4,1,0.7,1.6l0.3,0.6H585v3.9
			h-19.1v3.8h16.3c0,1.7-0.1,3.3-0.2,4.7c-0.1,1.5-0.3,2.9-0.6,4.4c-0.9,3.4-3,5-6.5,5c-1.2,0-2.4,0-3.5-0.1l-2.7-0.2l-0.5-3.8
			c0.5,0,1.9,0.2,1.9,0.2c1.1,0.1,2,0.1,3.1,0.1c0,0,0.1,0,0.1,0c0.7,0,1.5-0.2,2.1-0.7l0.1-0.1c0.6-0.5,1-1.3,1.2-2
			c0.1-0.5,0.2-1,0.2-1.5l0-2.1h-11.3l-0.2,0.7c-0.7,2.2-2.1,4.2-4,5.7c-2.3,1.8-4.8,3.2-7.5,4.2L550.9,432.6z M550.5,406h27.3v-3.6
			h-27.3V406z M638.1,436.5c-3.6-0.2-7.3-0.6-10.8-1.1c-3.1-0.5-6.3-1.2-9.3-2.2l-0.3-0.1l-0.3,0.1c-3.1,0.9-6.3,1.7-9.5,2.2
			c-3.4,0.6-6.9,1-10.4,1.1l-1.3-3.5c2.6-0.1,5.2-0.3,7.5-0.5c2.6-0.2,5.2-0.6,7.8-1.1l3-0.6l-2.8-1.3c-1.3-0.6-2.6-1.4-3.9-2.2
			c-1.5-1-2.9-2-4.2-3l-0.3-0.2h-3v-3.8h34v4.1c-1.4,0.9-2.9,1.7-4.5,2.6c-1.8,0.9-3.7,1.8-5.7,2.7l-2.9,1.3l3.2,0.6
			c2.5,0.5,5,0.8,7.5,1c2.3,0.2,4.8,0.3,7.5,0.4L638.1,436.5z M616.9,429l1.3-0.4c1-0.3,2.1-0.7,3.2-1.1c1-0.4,2-0.9,3-1.4l3.1-1.9
			h-18.6l3,1.9c0.9,0.6,1.9,1,2.8,1.4c0.7,0.3,1.4,0.5,2.2,0.8V429z M395.3,436.3c-0.8,0-1.6,0-2.4-0.1c-0.4-0.1-0.8-0.1-1.2-0.1
			c-0.2,0-0.3,0-0.5,0l-0.8-4.1c0.1,0,0.3,0,0.5,0.1c0.7,0.1,1.4,0.1,2,0.1c0,0,0.1,0,0.2,0c0.6,0,1.1-0.2,1.5-0.6l0.1-0.1l0.1-0.1
			c0.4-0.5,0.5-1.1,0.5-1.7l0-1.6h-9.1v8.3h-4.5l0-27.4h18.1v22.4C399.8,434.7,398.4,436.3,395.3,436.3z M386.2,424.4h9.1v-3.8h-9.1
			V424.4z M386.2,416.9h9.1v-3.8h-9.1V416.9z M330.8,436.3v-23.4l-2,7.2c-0.3,1.1-0.7,2.2-1.1,3.3c-0.2,0.4-0.3,0.8-0.5,1.2
			l-1.7-4.7c2.1-4.6,3.7-9.6,4.5-14.6l0.2-1.2h-3.8v-4.9h4.4v-8.5h3.9v8.5h3.2v4.9h-3.2v6.6l2.8-2c0.3,0.9,0.6,1.9,0.9,2.7l0.1,0.3
			c0.3,1,0.5,2,0.7,3.1l-2,1.3c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.7-0.3-1.4-0.4-2.1l-2,0.1v22.6H330.8z M201,436.3l0-17.5h-11.4v-5.1
			h11.5V397h-10.4v-5.1h25.3v5.1h-10.3v16.7h11.5v5.1h-11.5v17.5H201z M348.4,433.1c1-0.3,2.1-0.7,3.1-1.2c1.3-0.6,2.5-1.3,3.6-2.1
			l0.7-0.5l-0.3-0.8c-0.5-1.1-0.9-2.2-1.1-3.3c-0.3-1.3-0.6-2.6-0.7-3.9l-0.1-0.9h-6.2v2.6l0.5,0.3c0.8,0.5,1.6,1.1,2.4,1.7
			c0.6,0.5,1.2,1,1.8,1.6l-2.1,2.8c-0.2-0.2-0.4-0.4-0.6-0.6c-0.5-0.5-1-0.9-1.6-1.3l-1.1-0.7L346,428c-0.9,2.9-2.4,5.6-4.3,8
			l-3.4-2.7c1.2-1.4,2.1-3,2.8-4.7c0.9-2.2,1.6-4.5,1.9-6.9l0.2-1.2h-2.5v-3.5h2.3v-1.8h4.1v1.8h6v-1c0-0.3,0-0.5,0-0.8
			c0-0.3,0-0.5,0-0.8c0-0.6,0-1.2-0.1-1.8l-0.1-1.2l0.1,0c0-1.1,0-2.2-0.1-3.4c0-0.8,0-1.5,0-2.3c0,0-0.1-5.1-0.1-6.5l-0.1-3.8
			l0,0.1c0-0.6,0-1.2,0-1.8v-2.9h3.9v3.6c0,1.6,0,3,0.1,4.6l0.1,3.3l0,0l0,0.7l-0.1-0.3l0.3,14.4h4.8l-0.7-1.4
			c-0.1-0.1-0.1-0.2-0.2-0.3l3.5-0.8c0.3,0.3,0.5,0.6,0.7,0.9c0.3,0.4,0.5,0.8,0.7,1.1l0.3,0.5h2.3v3.5h-7.8l0.1,0h-3.3l0.1,1.1
			c0.1,0.7,0.2,1.4,0.3,2.1c0.1,0.7,0.3,1.4,0.5,2.1l0.4,1.7l1.2-1.2c0.6-0.6,1.2-1.2,1.8-2c0.5-0.6,1-1.2,1.4-1.9l2.5,1.8
			c-0.7,1-1.4,1.9-2.1,2.7c-0.7,0.9-1.5,1.8-2.4,2.6L361,430l0.2,0.6c0.2,0.5,0.5,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.1,0.6l0.1,0l0.1,0
			c0.3,0.1,0.5,0.1,0.8,0.1c1.1,0,1.9-0.6,2.1-1.7c0.1-0.5,0.2-1.1,0.3-1.7c0-0.3,0.1-0.5,0.1-0.8l2.8,0.9c0,0.7-0.1,1.3-0.2,2
			c-0.2,0.9-0.4,1.8-0.7,2.6c-0.8,1.5-2.3,2.4-4,2.4l-0.4,0c-2.3,0-4.6-1.1-5.9-3l-0.6-0.8l-0.8,0.5c-1.2,0.8-2.4,1.4-3.7,2.1
			c-1,0.5-2.1,0.9-3.1,1.2L348.4,433.1z M417.3,436c-0.1,0-0.3,0-0.4,0l-7.2,0c-1.5,0-2.7-0.4-3.4-1.1c-0.9-0.9-1.3-2.3-1.3-4.3
			v-15.8h4.7v6.5l1.3-0.5c1.7-0.6,3.4-1.4,4.9-2.3c1.4-0.9,2.8-1.8,4.1-2.8l2.5,3.8c-1.9,1.3-3.8,2.6-5.9,3.6c-2,1-4.1,1.9-6.3,2.5
			l-0.7,0.2v2.9c-0.1,0.6,0.1,1.3,0.5,1.8l0.1,0.1l0.1,0.1c0.5,0.3,1,0.5,1.6,0.5c0.1,0,0.2,0,0.3,0h3.2c0.7,0,2.3-0.3,2.7-2.7
			c0.1-0.7,0.2-1.3,0.2-2c0-0.2,0-0.5,0.1-0.8l4.8,1.6c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.5-0.4,2.5-0.4,2.5c-0.2,1.3-0.8,2.6-1.8,3.5
			C419.7,435.5,418.5,436,417.3,436z M744.7,435.5c-1.3-2.2-2.6-4.5-3.7-6.9c-1.2-2.7-2.3-5.4-3.1-8.2l-1.1-3.6l-0.9,3.6
			c-0.7,3-1.7,6-2.8,8.9c-0.9,2.1-1.9,4.2-3,6.2l-3.3-4.4l0.2-0.4c4.6-8.1,7-18,7-29.4v-10.5h4.7v10.3c0,5.4,0.8,10.7,2.4,15.8
			c1.6,5,3.9,9.8,6.8,14.2L744.7,435.5z M457.3,435.5c-4.9,0-7.2-2.3-7.2-7.1v-6.6l-15.4,1.5l-0.4-4.7l15.8-1.5v-6l-1,0.1
			c-2,0.1-4,0.2-6,0.4c-1.8,0.2-3.4,0.3-4.9,0.4l-0.8-4.9c4.7-0.3,9.7-0.6,14.9-1c5.5-0.4,11.3-0.9,17.4-1.5l0.9,4.7
			c-2,0.2-4.1,0.5-6.2,0.7c-2.3,0.2-4.8,0.4-8.2,0.6l-0.9,0.1v6l21-2l0.4,4.7l-21.4,2.1v5.5c0,2.6,1.6,4.1,4.4,4.1h7.3
			c0,0,0.1,0,0.2,0c0.8,0,1.7-0.2,2.4-0.6c0.8-0.5,1.3-1.2,1.6-2c0.3-1.1,0.5-2.2,0.7-3.3l0.2-1.5l5.2,1.5c-0.2,1.8-0.6,3.7-1.1,5.4
			c-0.9,3.5-3,5.2-6.9,5.2L457.3,435.5z M650.5,435.5v-5.1h18.6v-10.9h-14.4v-5.1h14.4v-10.3h-17.7v-5h20.4l0,0h20.3v5h-17.7v10.3
			h14.4v5.1h-14.4v10.9H693v5.1H650.5z M154.9,435.3l0-6.7h-17.8v-4.1l13.5-30.6h3.8L140.9,425h14l0-13.2h3.7V425h3v3.6h-3v6.7
			H154.9z M46.4,435.3v-6.7H28.6v-4.1l13.5-30.6h3.8L32.4,425h14v-13.2H50V425h3.1v3.6H50v6.7H46.4z M508,435.3v-4l14.2-19.9
			c0.9-1.2,1.6-2.5,2.1-3.8c0.5-1.2,0.7-2.5,0.8-3.8c0.1-1.8-0.5-3.5-1.7-4.8c-1.3-1.2-2.8-1.8-4.5-1.8c-0.1,0-0.2,0-0.4,0
			c-0.1,0-0.2,0-0.2,0c-1.7,0-3.3,0.7-4.5,1.9c-1.1,1.2-1.8,2.6-2.1,4.1h-4c0.3-1.7,0.9-3.3,1.8-4.9c0.9-1.5,2.2-2.8,3.7-3.6
			c1.5-0.8,3.3-1.3,5-1.3l0.6,0c1.9,0,3.8,0.4,5.5,1.2c1.5,0.7,2.7,1.9,3.5,3.4c0.9,1.7,1.3,3.7,1.2,5.6c0,1.5-0.3,3-0.8,4.5
			c-0.6,1.6-1.4,3.2-2.4,4.6l-13,18.6h16.5v3.8H508z M253.2,435.3v-37.6l-5.9,3.8v-4l5.2-3.6h4.5v41.4H253.2z M103,435.3v-4
			l14.2-19.9c0.9-1.2,1.5-2.5,2.1-3.8c0.5-1.2,0.7-2.5,0.8-3.8c0.1-1.8-0.5-3.5-1.7-4.8c-1.3-1.2-2.8-1.8-4.5-1.8
			c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.2,0c-1.7,0-3.3,0.7-4.6,1.9c-1.1,1.2-1.8,2.6-2.1,4.1h-4c0.3-1.7,0.9-3.3,1.8-4.9
			c0.9-1.5,2.2-2.8,3.7-3.6c1.5-0.8,3.3-1.3,5-1.3l0.6,0c1.9,0,3.8,0.4,5.5,1.2c1.5,0.7,2.7,1.9,3.5,3.4c0.9,1.7,1.3,3.7,1.2,5.6
			c0,1.5-0.3,3-0.8,4.5c-0.6,1.6-1.4,3.2-2.4,4.6l-13.1,18.6h16.5v3.8H103z M8.3,435.3v-37.6l-5.9,3.8v-4l5.2-3.6h4.5v41.4H8.3z
			 M705.6,434.7v-42.8h20.3v4.9h-6.1v7.3h6.1v18.6h-6.1v7.2h5.4v4.8H705.6z M710.4,429.8h4.7v-7.2h-4.7V429.8z M710.4,417.7h10.8
			v-8.9h-10.8V417.7z M710.4,404h4.7v-7.2h-4.7V404z M174.3,426.1l5-1.6v-17.3h-4.6v-5.5h4.6v-10.5h4.5v10.5h4.2v5.5h-4.2v16.3
			l4.3-1.4l0.3,5l-13,4.4L174.3,426.1z M83.1,419.7c-0.2,0-0.3,0-0.4,0c-0.7,0-1.4-0.1-2.1-0.3c-0.8-0.3-1.6-0.7-2.3-1.2l-0.9-0.6
			c-0.6-0.4-1.2-0.7-1.8-1c-0.6-0.3-1.2-0.4-1.8-0.5c0,0,0,0,0,0c-1,0-1.9,0.5-2.4,1.3c-0.4,0.7-0.6,1.5-0.6,2.3h-3
			c0-2.5,0.5-4.4,1.3-5.6c0.7-1.1,1.9-1.7,3.2-1.7c0.1,0,0.4,0,0.4,0c0.9,0,1.7,0.2,2.5,0.5c0.9,0.4,1.9,0.9,2.7,1.5l0.5,0.3h0
			c0.5,0.3,1.1,0.6,1.6,0.9c0.6,0.3,1.2,0.4,1.8,0.4c0,0,0,0,0,0c1,0,1.9-0.5,2.4-1.4c0.4-0.8,0.6-1.6,0.6-2.4h3
			c0,2.6-0.5,4.5-1.3,5.8c-0.7,1.1-1.9,1.7-3.2,1.7C83.3,419.7,83.2,419.7,83.1,419.7z M620.9,417.8v-16l-1.8,2.3
			c-0.2,0.2-0.3,0.4-0.5,0.7l-1.5-4.1c0.1-0.2,0.2-0.3,0.3-0.5l0.1-0.2v0.3l1.9-3.3c1.1-1.9,2-4,2.7-6.2l4,1.1
			c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.7-0.5,1.1l-0.9,1.5h5.6l-0.4-1.3c-0.2-0.6-0.3-1.3-0.4-2l4.2-0.7
			c0.1,0.4,0.2,0.9,0.3,1.3l0.1,0.5c0.1,0.5,0.2,0.9,0.2,1.3l0.2,0.8h5v3.6h-5.4v3.4h3.6v2.2h-3.6v3.4h3.6v2.2h-3.6v3.4h5.4v3.6H625
			v0.8H620.9z M625,413.3h4.7v-3.4H625V413.3z M625,407.6l4.7,0.1v-3.5H625V407.6z M625,402h4.7v-3.4H625V402z M599.7,417.7l0-16.3
			l-1.8,2.7c-0.1,0.2-0.3,0.4-0.4,0.6l-1.5-4.1c2.1-3,3.8-6.3,4.8-9.9l4.1,1.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.7-0.5,1.1
			l-0.9,1.5h5.6l-0.3-1.3c-0.2-0.6-0.3-1.3-0.4-2l4.2-0.7l0.8,3.9h4.5v3.6h-1.2l-0.4-1.2v1.2h-3.2v3.4h3.2v2.2h-3.2v3.5h3.2v2.2
			h-3.2v3.4h5v3.6h-13.8v0.8H599.7z M603.9,413.3h4.7v-3.4h-4.7V413.3z M603.9,407.6l4.7,0.1v-3.5h-4.7V407.6z M603.9,402h4.7v-3.4
			h-4.7V402z M340.8,409.6l1.6-2.3c0.8-1.1,1.6-2.1,2.3-3.1l1.6-2.1l-5.1,0.9l-0.5-3.1c1.4-2.5,2.8-5.2,4.1-8.1l3.1,1
			c-0.5,0.8-1,1.6-1.5,2.3c-0.8,1.1-1.5,2.2-2.1,3.2l-1,1.6h3.4l0.3-0.3c0.4-0.5,0.8-1,1.1-1.5c0.3-0.4,0.5-0.8,0.8-1.3l2.2,1.5
			l-0.3,0.5c-0.6,1-1.2,2.1-1.9,3.3c-0.6,1.1-1.4,2.2-2.2,3.3l-0.5,0.7l-3.2,4.2l5.4-1.3l-0.6-2.1l2.1-0.7c0.2,0.7,0.3,1.5,0.5,2.2
			c0.2,1.2,0.4,2.2,0.5,3.1l1.8-0.1l-3.7,1.1l-0.2-1.1l-7.4,1.4L340.8,409.6z M358.9,409.2l1.8-2.5c0.6-0.9,1.2-1.8,1.8-2.6l1.4-2.1
			l-4.1,1l-1.3-3.3l0.1-0.2c0.9-1.5,1.7-3,2.4-4.5c0.5-1.1,0.9-2.1,1.3-3l2.8,1.1c-0.5,0.9-0.9,1.7-1.4,2.5l-0.4,0.6
			c-0.4,0.8-0.8,1.5-1.2,2.1l-1.2,2l3.6-0.8l0.2-0.2c0.4-0.5,0.8-1,1.1-1.6c0.2-0.3,0.3-0.6,0.5-1l2.8,1.5c-0.7,1.1-1.3,2.1-1.9,3
			l-0.3,0.5c-0.8,1.2-1.7,2.5-2.6,3.8l-1.5,2.2l0,0c-0.1,0.1-0.2,0.2-0.3,0.4l-2.1,2.4l5.5-1.5l-0.4-2l2.3-0.6
			c0.3,0.9,0.5,1.8,0.7,2.7c0.2,0.8,0.3,1.7,0.4,2.6l-2.4,0.6l-0.2-1l-6.6,1.4L358.9,409.2z M417.6,411.5c-0.1,0-0.2,0-0.4,0l-7.6,0
			c-1.6,0-2.8-0.4-3.5-1.1c-0.8-0.8-1.2-2.1-1.2-3.9v-15.6h4.7v6.6l1.4-0.6c1.4-0.6,2.8-1.3,4.2-2c1.3-0.7,2.6-1.5,3.9-2.4l2.4,3.5
			c-1.7,1-3.4,2-5.2,3c-1.9,1-3.9,1.9-6,2.6l-0.7,0.2v2.7c-0.1,0.6,0.1,1.3,0.5,1.8l0.1,0.1l0.1,0.1c0.5,0.3,1,0.5,1.6,0.5
			c0.1,0,0.2,0,0.2,0h3.2c0,0,0.1,0,0.1,0c0.7,0,1.4-0.3,1.9-0.7c0.5-0.6,0.8-1.3,0.9-2c0.2-1,0.3-2,0.4-3l4.5,1.6
			c-0.1,0.6-0.2,1.3-0.3,1.9c-0.1,0.7-0.3,1.5-0.4,2.2c-0.2,1.3-0.8,2.5-1.7,3.4C419.9,411.1,418.8,411.5,417.6,411.5z M208.8,409.7
			c0.4-1.2,0.8-2.7,1.1-4.2c0.4-1.8,0.7-3.7,1-5.6l4.1,0.7c-0.2,1.4-0.5,3-0.7,4.6c-0.3,1.7-0.6,3.6-1,5.5L208.8,409.7z
			 M193.4,410.6c-0.3-1.8-0.6-3.7-1-5.4c-0.4-1.6-0.7-3-1.1-4.3l4.3-1c0.5,1.8,0.9,3.5,1.3,5.1c0.4,1.5,0.7,3,0.9,4.5L193.4,410.6z
			 M398.4,406.1c-0.2-0.3-0.3-0.6-0.5-1l-0.3-0.6l-16.3,1.2l-1.1-3.9c1.4-1.5,2.8-3.1,3.9-4.8c1.3-1.9,2.7-4.1,4.1-6.6l4.6,1.6
			c-1,1.6-1.9,3-2.8,4.2c-0.9,1.3-1.9,2.5-2.9,3.7l-1.7,1.9l10.9-0.9l-0.7-1.3c-0.3-0.6-0.7-1.2-1.1-1.8l-0.7-1l3.8-1.9
			c1,1.4,1.9,2.9,2.8,4.5c0.8,1.5,1.6,3.1,2.2,4.7L398.4,406.1z M470.8,403.4v-3.9H440v3.9H435v-8.7h18l-0.9-1.5
			c-0.2-0.3-0.5-0.7-0.8-1.2c-0.1-0.1-0.1-0.2-0.2-0.3l6-1.2c0.8,1.1,1.4,2.2,1.8,3.5l0.2,0.7h16.8v8.7H470.8z M670,397.5
			c-0.7-0.7-1.4-1.4-2.4-2.2c-0.5-0.5-1-0.9-1.5-1.3l3.9-3.4c1.1,0.7,2.1,1.6,3.1,2.4c0.9,0.8,1.8,1.6,2.6,2.4l-4.4,3.2L670,397.5z"
			/>
		<path class="st0 logo1" d="M234.6,126.1c3-2.3,9.8-8.3,9.8-21.8v-1.2l-23.9,3.7v-4.7l23.9-3.7V85.8h-23.9v-4.3h23.9V69.4l-1.1,0.1
			c-2,0.2-7.5,0.7-22,2.3l-0.8,0V67l57.2-6.4v4.8l-15.1,2.1v14h32.6l-0.2-1.2c-0.6-3.7-1.4-7.4-2.4-11c-0.4-1.6-0.8-3.5-1.1-5.6
			c-0.1-0.8-0.3-1.6-0.4-2.4h16.6l0.1,1.1l0,0.1c0.3,1.2,0.7,2.6,1.1,4.3c1.2,4.5,2.6,10.1,3,13.8l0.1,0.9h27.3v4.3h-25.1v2
			c-0.3,0-0.4-0.1-0.5-0.2c0.2,0.3,0.6,1.7,0.8,2.6c0.1,0.4,0.2,0.7,0.3,1c0.4,1.5,0.8,2.5,1.2,3.6c0.5,1.3,1,2.7,1.7,5.3
			c0.1,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.5,0.2,0.7l0.3,0.8l21-3.4v5.6l-19,3.1l0.5,1.2c3.3,7.9,7.5,11.6,13.4,11.6h5.2v5.6h-14.7
			c-7.5,0-12.9-2.2-15.9-6.7c-1.4-2-2.4-4.8-3.2-6.8c-0.2-0.6-0.4-1.1-0.6-1.5l-0.3-0.7l-26.3,4.3l0.1-4.7l24.3-3l-0.4-1.2
			c-0.2-0.7-0.6-2.3-1.1-4.1c-1.8-7.1-3.5-13.6-5.1-15.5l-0.3-0.4h-33.3v9.9l1.2-0.2c9.5-1.8,16-3.4,18.9-4.6v5.8l-20.1,3.7v7.2
			c0,9.6-9.9,18.5-14.4,18.5H234.6z M181,125.8V84.4h-40.9v30.5h34.1v5.7h-34.1v5.2h-16.4V82.2l-0.2-0.3c-0.3-0.4-1.3-1.5-2.7-3.1
			h76.7v47H181z M91.5,125.8V53.6H79.3c0.6-0.9,1.2-1.9,1.7-2.9c3.2-6.3,5.8-13,7.7-19.9c2.1-7.2,3.2-14.7,3.3-22.2v-1l-0.3-0.3
			C91,6.5,90.1,5.7,89.1,5h23.5c-0.8,5.7-2,11.4-3.5,16.9c-1.6,5.9-3.7,11.8-6.2,17.4c-1.3,2.7-2.7,5.4-4.4,7.9l-1,1.5h10.2v77H91.5
			z M389.4,98.1c-2.9,0-5.8-0.8-8.3-2.2c-4.1-2.2-7.3-6.1-9.3-11.5c-1.1-3.1-1.6-6.4-1.6-9.7V26.8c0-7.6,2.5-13.9,7.4-18.6
			c3.2-3,7.4-4.6,11.8-4.7h51.7v6.1h-55.5c0,0-0.1,20.4-0.1,20.6c0,7.2,0,20.9-0.1,41.2v20.5h55.7l0,6.2H389.4z M560.8,98.1v-6.2
			h60.8V66.4c0-3.6-0.6-6-1.7-7.4c-2-3.2-6.6-4.9-13.5-4.9h-6.7c-8.1,0-17.7,0-28.9-0.1c-1.5-0.1-3.1-0.4-4.6-0.9
			c-2.8-1-5.1-3.1-6.4-5.8c-1.3-3.2-2-6.7-1.9-10.2V18.4c-0.1-3.9,1.5-7.6,4.4-10.2c3.7-3.2,8.7-4.8,14.9-4.8h53.8v6.1h-57.7v24.9
			c0,3.8,0.4,6.4,1.3,8c0.8,1.6,2,2.9,3.6,3.8c1.7,1.1,4.8,1.6,9.7,1.6h30c6.2,0,11.2,1.7,14.9,4.9c2.7,2.6,4.2,6.3,4.1,10.1v19
			c0,5.5-2.4,9.8-7.2,13c-3.5,2-7.6,3-11.7,3L560.8,98.1z M728.3,98.1l-11-39.8h-44.2l-10.9,39.8H647c10.6-39.9,16.2-61,16.6-62.8
			c0.2-0.7,1.4-4.8,7.5-27.9l0.1-0.5l-2.9-3.5h50.3c1.2,4.9,6.6,25,15.9,60l9.1,34.6H728.3z M674.8,52.2h40.5v-1.1l0-0.2L704,9.6
			h-17.5L674.8,52.2z M532.8,98.1l-11-39.8h-44.1l-10.9,39.8h-15.3c10.7-40.4,16.1-60.9,16.6-62.8c0.1-0.4,0.8-2.7,7.5-27.9l0.1-0.5
			l-2.9-3.5H523c1.3,4.8,6.6,25,15.9,60l9.1,34.6H532.8z M479.3,52.2h40.5v-1.1l0-0.2L508.5,9.6H491L479.3,52.2z M323.9,74.5
			c-1.4-3.4-3.5-10.6-4.2-13.1h16c-0.1,3.3,0.1,6.5,0.4,9.8l-0.1,3.3H323.9z M126.1,65.4v-5.7h67.6v5.7H126.1z M220.6,56.9v-4.3
			h47.8V39.2h-40.3v-3h40.3v-6.2l-5.4-4.7h-41.2v-6.8h21.5v-2c-1.3,0-2.8-2-4-3.5l-0.5-0.6c-2.9-4.2-4.8-7.1-5.8-10.1h20.5
			c0.2,1.3,0.6,2.8,1.1,5l0.2,0.7c0.9,2.6,2,5.1,3.5,7.4l0.2,0.3c1,1.5,2,2.9,3.3,2.9h37.5c0.6,0,1.6-0.3,2.6-1.7
			c0.4-0.6,0.8-1,1.1-1.5c0.8-1,1.5-2,2.1-3.3c1-2.3,1.9-4.7,2.4-7.2l0.1-0.5L307.4,4c-0.1-0.1-0.3-0.3-0.4-0.6
			c-0.4-0.6-1-1.3-1.5-1.8c-0.2-0.2-0.4-0.4-0.5-0.6h25.3c-1.5,3.3-3.3,6.4-5.3,9.4c-2,2.8-5.4,6.2-6.5,6.2v2h19.7v6.8h-47.8v10.9
			h44.1v3h-44.1v13.3h49.1v4.3H220.6z M126.1,47.4v-5.7h67.6v5.7H126.1z M117.5,29.3v-5.6h33.2l-0.5-1.3c-1.9-5.3-3.4-10.8-4.6-16.3
			c-0.1-0.3-0.2-0.7-0.2-1h18.8c0.1,3.3,0.6,9.3,1.5,17.8l0.1,0.9h36.5v5.6H117.5z"/>
	<path class="st0 logo_l" d="M3.5,124.7l-1-3h0.8l0.6,2.1l0,0l0.6-2.1h0.8l-1,3v2.2H3.5V124.7z M5.7,121.7h2.3v0.7H6.4v1.5h1.3v0.7H6.4v1.6
		h1.5v0.8H5.7V121.7z M8.4,121.7h0.7l1.1,3.2l0,0v-3.2H11v5.3h-0.7l-1.2-3.2l0,0v3.2H8.4V121.7z M13.4,125.7c0,0.7-0.5,1.3-1.2,1.3
		c0,0-0.1,0-0.1,0c-0.4,0-0.8-0.2-1-0.5l0.6-0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.5-0.1,0.5-0.6v-3.9h0.8L13.4,125.7z M14.2,121.7
		h0.8v5.3h-0.8V121.7z M15.7,121.7h0.7l1,2.8l0,0l1-2.8h0.7v5.3h-0.8v-3.2l0,0l-0.8,2.3h-0.4l-0.7-2.3l0,0v3.2h-0.8L15.7,121.7z
		 M21.2,121.7h0.8v5.3h-0.8V121.7z M22.6,121.7h0.7l1.1,3.2l0,0v-3.2h0.8v5.3h-0.7l-1.1-3.2l0,0v3.2h-0.8L22.6,121.7z M26.4,122.4
		h-0.9v-0.7h2.5v0.7h-0.9v4.6h-0.8L26.4,122.4z M28.4,121.7h2.3v0.7h-1.5v1.5h1.3v0.7h-1.3v1.6h1.5v0.8h-2.3V121.7z M31.1,121.7h1.2
		c0.9,0,1.3,0.5,1.3,1.6c0.1,0.5-0.2,1-0.7,1.3l0.8,2.4H33l-0.7-2.2h-0.5v2.2h-0.8V121.7z M31.9,124h0.4c0.5,0,0.6-0.3,0.6-0.8
		s-0.1-0.8-0.6-0.8h-0.4V124z M34.3,121.7H35l1.1,3.2l0,0v-3.2h0.8v5.3h-0.7l-1.2-3.2l0,0v3.2h-0.8V121.7z M38.4,121.7H39l1.2,5.3
		h-0.8l-0.2-1.1h-1l-0.2,1.1h-0.8L38.4,121.7z M38.7,123.2L38.7,123.2l-0.4,1.9h0.8L38.7,123.2z M40.7,122.4h-0.9v-0.7h2.5v0.7h-0.9
		v4.6h-0.8V122.4z M42.7,121.7h0.8v5.3h-0.8V121.7z M44.1,122.9c-0.1-0.7,0.5-1.3,1.2-1.4c0.7-0.1,1.3,0.5,1.4,1.2
		c0,0.1,0,0.2,0,0.2v2.8c0.1,0.7-0.5,1.3-1.2,1.4c-0.7,0.1-1.3-0.5-1.4-1.2c0-0.1,0-0.2,0-0.2V122.9z M44.9,125.7
		c0,0.3,0.2,0.5,0.4,0.5c0,0,0,0,0.1,0c0.3,0,0.5-0.2,0.5-0.5c0,0,0,0,0-0.1v-2.8c0.1-0.3-0.1-0.5-0.4-0.6s-0.5,0.1-0.6,0.4
		c0,0.1,0,0.1,0,0.2L44.9,125.7z M47.3,121.7H48l1.1,3.2l0,0v-3.2h0.8v5.3h-0.7l-1.2-3.2l0,0v3.2h-0.8L47.3,121.7z M51.4,121.7H52
		l1.2,5.3h-0.8l-0.2-1.1h-1l-0.2,1.1h-0.8L51.4,121.7z M51.7,123.2L51.7,123.2l-0.4,1.9h0.8L51.7,123.2z M53.6,121.7h0.8v4.5h1.5
		v0.8h-2.3V121.7z M48.6,60.4H30.7v-5.5h-3.1v5.5H9.7v3.1h38.9V60.4z M46,78v-6.5c0-2.2-1.8-4.1-4.1-4.1H16.2
		c-2.2,0-4.1,1.8-4.1,4.1l0,0V78c0,2.2,1.8,4.1,4.1,4.1h11.3v16.3h3.1V82.1h11.4C44.2,82.1,46,80.3,46,78z M16.2,79
		c-0.6,0-1-0.4-1-1v-6.4c0-0.6,0.4-1,1-1h25.7c0.6,0,1,0.4,1,1V78c0,0.6-0.4,1-1,1L16.2,79z M51.8,0.9H6.5C4.2,0.9,2.4,2.7,2.4,5
		c0,0,0,0,0,0v89.6c0,2.2,1.8,4.1,4.1,4.1h8.2c2.2,0,4.1-1.8,4.1-4.1v-8.5h-3.1v8.5c0,0.6-0.4,1-1,1H6.5c-0.6,0-1-0.4-1-1V5
		c0-0.6,0.4-1,1-1h45.3c0.6,0,1,0.4,1,1v89.6c0,0.6-0.4,1-1,1h-8.2c-0.5,0-1-0.5-1-1V86h-3.1v8.6c0,2.2,1.8,4.1,4.1,4.1h8.2
		c2.2,0,4.1-1.8,4.1-4.1V5C55.9,2.7,54.1,0.9,51.8,0.9C51.8,0.9,51.8,0.9,51.8,0.9z M13.6,26c-2.2,0-4,1.9-4,4.1v19.8h3.1V37.2h6.6
		v2.1c0.1,2.1,1.3,4,3.3,4.9l2.7,1.2l-7,3l1.2,2.8l9.7-4.2l9.7,4.2l1.2-2.8l-7-3l2.7-1.2c1.9-0.9,3.2-2.8,3.3-4.9v-2.1h6.6v12.7h3.1
		V30.1c0-2.2-1.8-4.1-4-4.1c0,0,0,0-0.1,0H30.6v-2.2h12.7c2.2,0,4.1-1.8,4.1-4.1v-6.9c0-2.2-1.8-4.1-4.1-4.1H15
		c-2.2,0-4.1,1.8-4.1,4.1v6.9c0,2.2,1.8,4.1,4.1,4.1h12.5V26H13.6z M35.9,39.3c-0.1,0.9-0.6,1.7-1.3,2.1l-5.4,2.3l-5.4-2.3
		c-0.8-0.4-1.3-1.2-1.4-2.1v-2.1h13.5L35.9,39.3z M14,12.9c0-0.6,0.4-1,1-1h28.3c0.6,0,1,0.4,1,1v1.8H14V12.9z M15,20.7
		c-0.6,0-1-0.4-1-1v-2h30.3v2c0,0.6-0.4,1-1,1H15z M44.7,29.1c0.6,0,1,0.4,1,1v4H39V31h-3.1v3.1H22.4V31h-3.1v3.1h-6.6v-4
		c0-0.6,0.4-1,1-1H44.7z M2.3,116.5c1,0,1.9-0.1,2.8-0.2c0.8-0.1,1.6-0.2,2.4-0.4c-1.2-0.2-2.5-0.4-3.8-0.7l1.3-1.3H2.4v-1.1H6
		l0.7-0.8l1.6,0.2l-0.6,0.6h6.2v1.1h-2.3c-0.3,0.6-0.8,1.1-1.3,1.5l1.6,0.4l1.8,0.6l-0.7,1.1c-1.3-0.5-2.5-0.9-3.8-1.2
		c-2.1,0.8-4.3,1.2-6.6,1.2L2.3,116.5z M2.7,110.8h4.7L7,110.3H3.7v-3.9h8.8v3.9H8.6l0.3,0.6h4.7v1.8h-1.4V112H4.1v0.7H2.7V110.8z
		 M11.1,107.8v-0.5h-6v0.5H11.1z M11.1,109.1v-0.4h-6v0.4H11.1z M9,115.1c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.4,0.5-0.6H6.8
		l-0.7,0.6C6.9,114.7,7.8,114.9,9,115.1L9,115.1z M16.3,116c0.6-0.3,1.1-0.6,1.6-1c0.5-0.4,1-0.8,1.4-1.2l1.1,0.9
		c-0.5,0.5-1.1,1-1.6,1.4c-0.6,0.4-1.1,0.8-1.7,1.1L16.3,116z M16.5,107.3h5.2c-0.4-0.3-0.8-0.6-1.2-0.8l1.2-0.8
		c0.6,0.2,1.1,0.6,1.5,1l-0.9,0.6H28v1.3H16.5V107.3z M21.4,117.5h-0.7H20l-0.4-1.3l0.8,0.1c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.3-0.1
		c0.1-0.1,0.1-0.2,0.1-0.3v-2.4h-3.9v-4h9.1v4H23v2.7c0,0.4-0.1,0.7-0.4,1C22.3,117.4,21.9,117.5,21.4,117.5L21.4,117.5z
		 M25.4,112.2v-1.4h-6.3v1.4H25.4z M27.1,117.3c-1-0.9-2.1-1.7-3.2-2.4l1-1c0.5,0.3,1,0.7,1.6,1.1c0.5,0.4,1.1,0.8,1.5,1.2
		L27.1,117.3z M33.7,109.9l0.5,1c0.1,0.3,0.2,0.5,0.3,0.8l-1,0.6c-0.1-0.5-0.3-0.9-0.5-1.3v6.6h-1.3v-5.4c-0.1,0.4-0.2,0.8-0.4,1.3
		s-0.4,1-0.6,1.6l-0.6-1.7c0.6-1.2,1.1-2.6,1.5-3.9h-1.2v-1.3h1.3V106h1.3v2.1h1v1.3h-1v1L33.7,109.9z M38.9,110.6
		c0.2-0.2,0.3-0.4,0.5-0.7s0.4-0.5,0.6-0.8l-0.9,0.2l-0.3-0.5C38.8,109.4,38.8,110,38.9,110.6c-0.1,0.6-0.1,1.2-0.1,1.8h0.8
		c0-0.1-0.1-0.3-0.1-0.4l1.3-0.3c0.2,0.3,0.3,0.5,0.5,0.7h0.7v1.2h-3c0,0.4,0.1,0.9,0.3,1.3c0.4-0.4,0.7-0.8,1-1.2l1,0.6
		c-0.4,0.6-0.9,1.1-1.5,1.6l0.2,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.2,0.1-0.3,0.1-0.5
		c0-0.2,0.1-0.4,0.1-0.7l1.2,0.5c-0.1,0.5-0.2,0.9-0.3,1.4c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.5,0.2-0.7,0.2
		c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.6,0.4-1.3,0.7-2,0.9l-0.8-1c0.4-0.1,0.8-0.2,1.2-0.4c0.3-0.1,0.6-0.3,0.9-0.5
		c-0.3-0.6-0.5-1.3-0.5-2h-1.2v0.3c0.5,0.3,1,0.6,1.4,1l-0.7,1.1c-0.3-0.3-0.6-0.6-1-0.8c-0.3,0.9-0.8,1.7-1.5,2.3l-1-0.9
		c0.9-0.8,1.5-1.9,1.5-3.1h-0.8v-1.2h0.8V112h1.3v0.4h1.2c0-0.2,0-0.5,0-0.8l-0.9,0.3l-0.1-0.4l-1.9,0.4l-0.3-1
		c0.2-0.3,0.5-0.5,0.7-0.8s0.4-0.5,0.5-0.8l-1.2,0.2l-0.2-0.9c0.5-0.8,1-1.6,1.3-2.4l1.2,0.3c-0.4,0.6-0.8,1.2-1.2,1.8l0.5-0.1
		c0.2-0.2,0.4-0.5,0.5-0.8l0.9,0.5V106h1.2v2.4c0.3-0.4,0.5-0.7,0.7-1.1c0.2-0.4,0.4-0.7,0.5-1.1l1.3,0.3l-0.5,0.9
		c-0.2,0.3-0.3,0.5-0.5,0.8h0.4c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4l1.1,0.5c-0.2,0.4-0.5,0.7-0.7,1s-0.5,0.7-0.8,1
		l0.9-0.3c0.1,0.3,0.2,0.6,0.3,0.9s0.2,0.6,0.2,0.9l-1,0.3l-0.2-0.4l-1.8,0.3L38.9,110.6z M37.6,108.1c-0.4,0.7-0.8,1.4-1.3,2
		l-0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.3l0.6-0.1l-0.1-0.4l0.9-0.3c0.1,0.2,0.2,0.4,0.2,0.7c0.1,0.2,0.1,0.5,0.2,0.7V108.1z M46,112.2
		c-0.2,0.8-0.6,1.7-1,2.8l-0.5-1.7c0.6-1.2,1.1-2.5,1.4-3.9h-1.3v-1.3H46V106h1.3v2.1h1.1v1.3h-1.1v1.6l0.8-0.4
		c0.2,0.4,0.3,0.8,0.5,1.2s0.2,0.7,0.3,1.1l-1,0.7c-0.1-0.4-0.2-0.7-0.3-1s-0.2-0.5-0.3-0.7v5.7H46V112.2z M49.1,115.6h-0.7v-1.1
		h0.7v-3.2h2.6v-0.5h-3.3v-1.1h1.8v-0.5h-1.3v-1h1.3v-0.5h-1.5v-1.1h1.5V106h1.3v0.7H53V106h1.3v0.7h1.5v1.1h-1.5v0.5h1.3v1h-1.3
		v0.5h1.8v1.1h-3.3v0.5h2.6v3.2h0.7v1.1h-0.7v0.6c0,0.4-0.1,0.7-0.3,1c-0.3,0.2-0.6,0.4-1,0.3h-0.7h-0.8l-0.3-1.1l0.8,0.1h0.7
		c0.3,0,0.4-0.1,0.4-0.4v-0.5h-3.9v1.9h-1.3V115.6z M51.6,113v-0.6h-1.3v0.6H51.6z M51.6,114.5V114h-1.3v0.6H51.6z M53,108.2v-0.5
		h-1.5v0.5H53z M53,109.7v-0.5h-1.5v0.5H53z M54.2,113v-0.6h-1.3v0.6H54.2z M54.2,114.5V114h-1.3v0.6H54.2z"/>
	<path class="st0 logo_l" d="M372.6,120.1c0-1.6,0-3.5-0.1-4c-0.4-0.1-0.9-0.2-1.4-0.3l-0.1-0.4c0.5,0,1.6,0.1,2.9,0.1c0.8,0,2.1,0,2.5-0.1
		l-0.1,0.4c-0.5,0.1-1.2,0.1-1.8,0.3c0,0.3-0.1,2.5-0.1,4v2.3c0,3.7,1.5,5,3.4,5c0.9,0.1,1.8-0.2,2.5-0.8c0.9-0.9,1.1-2.4,1.1-4.6
		v-1.6c0-0.6,0-3.8-0.1-4.4c-0.4-0.1-1.4-0.2-1.9-0.3l-0.1-0.4c0.4,0,1.7,0.1,2.8,0.1c0.9,0,1.8,0,2.1-0.1l-0.1,0.4
		c-0.3,0.1-0.8,0.1-1.2,0.3c0,0.3-0.1,2.5-0.1,2.9v0.8c0,0.7,0,1.5,0,2.2c0,2-0.4,3.7-1.7,4.9c-1,0.9-2.3,1.3-3.6,1.2
		c-3.5,0-5.2-2.3-5.2-5.3L372.6,120.1z M387.4,115.5c1,0,2.6,0,3.9-0.1c0.4,0.6,3.8,5,4.1,5.4c0.4,0.5,3.2,3.9,3.7,4.4
		c0-2,0-8.2-0.4-9.1c-0.3-0.1-1.3-0.2-1.7-0.3l-0.1-0.4c0.3,0,2.2,0.1,2.5,0.1s1.8,0,2.2-0.1l-0.1,0.4c-0.2,0-0.8,0.2-1.3,0.3
		c-0.2,1.2-0.3,2.5-0.3,3.7c0,0.8-0.2,8.2-0.3,8.9c-0.5-0.5-5.3-6.2-5.6-6.6c-0.2-0.3-3.5-4.4-4-5c0,0.4,0.1,9.6,0.4,10.3
		c0.3,0.1,1.5,0.2,1.9,0.2v0.4c-0.3,0-2.1-0.1-2.5-0.1s-1.8,0-2.3,0.1v-0.4c0.5,0,1-0.1,1.4-0.2c0.3-0.7,0.5-10.6,0.5-11.2
		c-0.6-0.1-1.2-0.2-1.9-0.2L387.4,115.5z M408.6,121.7c0,2.3,0,5.2,0.1,5.6c0.4,0.1,1.5,0.2,1.8,0.2l0.1,0.4c-0.4,0-2.2-0.1-3.2-0.1
		c-0.6,0-2.1,0-2.7,0.1l0.1-0.4c0.3,0,1.5-0.2,1.8-0.2c0.1-0.7,0.1-4,0.1-5.7v-1c0-0.6,0-3.9-0.1-4.5c-0.4-0.1-1.5-0.2-1.8-0.3
		l-0.1-0.4c0.3,0,1.9,0.1,3.2,0.1c0.9,0,2.4,0,2.6-0.1l-0.1,0.4c-0.4,0-1.2,0.2-1.8,0.3c-0.1,0.3-0.1,2.4-0.1,4.6L408.6,121.7z
		 M428.9,132c-1.1-0.2-5.6-3.2-6.5-4l-0.8,0.1c-0.3,0-0.7,0.1-1,0.1c-1.9,0.1-3.8-0.6-5.2-1.9c-1.1-1.2-1.7-2.8-1.6-4.4
		c0-3.3,2.6-6.6,7.5-6.6c1.9-0.1,3.9,0.6,5.3,1.9c1,1.1,1.6,2.5,1.6,3.9c0,2.5-1.3,4.9-3.5,6.1c1.4,1.7,3,3.3,4.7,4.6L428.9,132z
		 M426.1,122c0-3.4-2.7-6.1-5.5-6.1c-2.5,0-4.8,1.9-4.8,5.3c0,3,2.6,6.3,5.6,6.3S426.1,124.9,426.1,122z M432.9,120.1
		c0-1.6,0-3.5-0.1-4c-0.4-0.1-0.9-0.2-1.4-0.3l0-0.4c0.5,0,1.6,0.1,2.9,0.1c0.8,0,2.1,0,2.5-0.1l-0.1,0.4c-0.5,0.1-1.2,0.1-1.8,0.3
		c0,0.3-0.1,2.5-0.1,4v2.3c0,3.7,1.5,5,3.4,5c0.9,0.1,1.8-0.2,2.5-0.8c0.9-0.9,1.1-2.4,1.1-4.6v-1.6c0-0.6,0-3.8-0.1-4.4
		c-0.4-0.1-1.4-0.2-1.9-0.3l-0.1-0.4c0.4,0,1.7,0.1,2.8,0.1c0.9,0,1.8,0,2.1-0.1l-0.1,0.4c-0.3,0.1-0.8,0.1-1.2,0.3
		c0,0.3-0.1,2.5-0.1,2.9v0.8c0,0.7,0,1.5,0,2.2c0,2-0.4,3.7-1.7,4.9c-1,0.9-2.3,1.3-3.6,1.2c-3.5,0-5.2-2.3-5.2-5.3L432.9,120.1z
		 M449.8,121c0-1.6,0-4.4-0.1-4.9c-0.3-0.1-1.4-0.2-1.8-0.3l-0.1-0.4l1.5,0.1h1.5c0.5,0,4.6,0,5-0.1c0.1-0.3,0.1-0.6,0.1-0.8
		l0.4-0.1c-0.1,0.4-0.3,3.1-0.3,3.7l-0.4-0.1c0-0.1,0-0.9,0-1.3c-0.3-0.5-2.9-0.7-3.8-0.7c0,0.2-0.1,3.6-0.1,4.7v0.3
		c1.2,0,2.4-0.1,3.5-0.3c0.1-0.2,0.1-0.5,0.2-0.8h0.4c0,0.3-0.4,2.7-0.5,3.3l-0.3-0.2c0-0.4,0-0.8,0-1.2c-1.1-0.3-2.2-0.5-3.3-0.4
		v0.3c0,1.1,0.1,4.9,0.1,5.4c1.4,0.1,2.8-0.1,4.1-0.6c0.2-0.6,0.3-1.2,0.4-1.6l0.4-0.1c-0.1,0.5-0.5,3.2-0.6,4.1l-0.4-0.1
		c0-0.5,0-0.8,0-1.1c-0.5-0.1-4.3-0.2-5.4-0.2c-0.6,0-1.9,0-2.6,0.1l0.1-0.4c0.6,0,1.2-0.1,1.8-0.2c0.1-0.3,0.1-3.6,0.1-5.5
		L449.8,121z M468.4,121c0-1.6,0-4.4-0.1-4.9c-0.6-0.1-1.2-0.2-1.8-0.3l-0.1-0.4c0.6,0,2.3,0.1,3,0.1s5.1,0,5.4-0.1
		c0.1-0.2,0.2-0.9,0.2-1.3l0.4-0.1c-0.2,1.4-0.3,2.9-0.3,4.3l-0.4-0.1c0-0.3,0-1-0.1-1.4c-0.6-0.6-3.4-0.8-4.2-0.8
		c-0.1,0.3-0.1,3.4-0.1,4.7v0.4c1.2,0,2.4-0.1,3.6-0.3c0.1-0.3,0.2-0.7,0.2-1l0.4-0.1c-0.1,0.8-0.2,2.6-0.3,3.9l-0.4-0.1
		c0-0.4,0-1-0.1-1.5c-1.1-0.3-2.3-0.5-3.5-0.4v0.5c0,1.5,0,4.6,0.1,5.1c0.6,0.1,1.2,0.2,1.9,0.2l0.1,0.4c-0.6,0-2-0.1-3.1-0.1
		c-1,0-2.3,0-2.9,0.1l0.1-0.4c0.6,0,1.2-0.1,1.8-0.2c0-0.4,0.1-3.3,0.1-5.2L468.4,121z M485.1,127.6c0.6,0,1.2-0.1,1.7-0.2
		c-0.3-0.9-1.5-4.7-1.8-5.2c-0.6,0-3.3,0.1-4,0.2c-0.5,1.6-0.9,3.3-1.2,4.9c0.5,0.1,1,0.2,1.5,0.2l0.1,0.4c-0.5,0-2.1-0.1-2.6-0.1
		s-1.6,0-2,0.1l0.1-0.4c0.5-0.1,1-0.1,1.5-0.2c0.6-1.1,4-10.6,4.3-11.3c-0.4-0.1-0.9-0.2-1.4-0.2v-0.4c0.5,0,1.9,0.1,2.2,0.1
		c0.7,0,2.4,0,2.9-0.1l0,0.4c-0.4,0-0.9,0.1-1.3,0.2c0.2,0.8,1.1,3.5,1.8,5.4c0.5,1.3,1.5,4.3,2.3,5.9c0.5,0.1,1,0.2,1.5,0.2v0.4
		c-0.2,0-1.9-0.1-2.8-0.1c-0.5,0-2.4,0-2.9,0.1L485.1,127.6z M483.2,116.4L483.2,116.4c-0.5,1.6-1.6,4.6-1.8,5.2l3.6,0.1
		C484.6,120.5,483.5,117.3,483.2,116.4L483.2,116.4z M492.6,129.6c0-0.3,0.3-2.2,0.3-2.9c0-0.5,0.1-2.4,0-2.8l0.4,0.1
		c0,0.5,0.1,1,0.1,1.6c0.4,1.1,1.5,1.9,2.6,1.9c1.3,0.1,2.3-0.9,2.4-2.1c0,0,0-0.1,0-0.1c0-1.4-1.1-2.1-2.2-2.9
		c-1.7-1.1-3-2.1-3-4.1c0-1.8,1.5-3.2,3.2-3.1c0.1,0,0.2,0,0.3,0c0.8,0,1.6,0.1,2.3,0.3c0.1-0.4,0.2-0.9,0.3-1.4h0.4
		c-0.2,1.6-0.4,3.2-0.4,4.8l-0.4-0.2c0-0.4,0-0.9-0.1-1.3c-0.4-0.9-1.3-1.6-2.3-1.6c-1-0.1-1.9,0.6-2.1,1.6c0,0,0,0.1,0,0.1
		c0,1.1,1.2,1.8,2.5,2.6s2.9,1.9,2.9,4.1c0,2.4-1.9,3.8-4.2,3.8c-0.8,0-1.6-0.1-2.4-0.4c-0.1,0.3-0.3,1.2-0.4,1.8L492.6,129.6z
		 M505.5,120.5c0-0.6,0-3.9-0.1-4.5c-0.3-0.1-1.4-0.2-1.8-0.3l-0.1-0.4c0.3,0,1.9,0.1,3,0.1s2.5,0,2.8-0.1l-0.1,0.4
		c-0.6,0.1-1.1,0.1-1.6,0.3c-0.1,0.5-0.1,2.6-0.1,4.1v0.8c0.4,0,6.4,0.1,7.1-0.1v-0.8c0-1.4,0-2.7-0.1-4.1c-0.6-0.1-1.1-0.2-1.7-0.3
		l-0.1-0.4c0.3,0,1.9,0.1,2.9,0.1s2.5,0,2.9-0.1l-0.1,0.4l-1.7,0.3c-0.1,0.3-0.1,4-0.1,4.5v1.2c0,2,0,5,0.2,5.6
		c0.3,0.1,1.4,0.2,1.7,0.2l0.1,0.4c-0.3,0-2.1-0.1-2.9-0.1s-2.3,0-2.9,0.1l0.1-0.4c0.3,0,1.4-0.2,1.7-0.2c0.1-0.6,0.1-2.8,0.1-4.7
		v-1c-0.8,0-6.9,0.3-7.1,0.4v0.6c0,1.8,0.1,4,0.1,4.7c0.5,0.1,1.1,0.2,1.6,0.2l0.1,0.4c-0.4,0-2.1-0.1-2.8-0.1c-0.9,0-2.5,0-3,0.1
		l0.1-0.4c0.6-0.1,1.2-0.1,1.8-0.2c0.1-0.8,0.1-3.8,0.1-5.1L505.5,120.5z M525.7,121.7c0,2.3,0,5.2,0.1,5.6c0.4,0.1,1.5,0.2,1.8,0.2
		l0.1,0.4c-0.4,0-2.2-0.1-3.2-0.1c-0.6,0-2.2,0-2.7,0.1l0.1-0.4c0.3,0,1.5-0.2,1.8-0.2c0.1-0.7,0.1-4,0.1-5.7v-1
		c0-0.6,0-3.9-0.1-4.5c-0.3-0.1-1.5-0.2-1.8-0.3l-0.1-0.4c0.3,0,1.9,0.1,3.3,0.1c0.9,0,2.3,0,2.6-0.1l-0.1,0.4
		c-0.3,0-1.2,0.2-1.8,0.3c-0.1,0.3-0.1,2.4-0.1,4.6L525.7,121.7z M532.5,126.2c-1.1-1.1-1.7-2.7-1.6-4.3c0-3.5,2.8-6.7,7.5-6.7
		c1.7-0.1,3.4,0.4,4.7,1.4c1.3,1.1,2.1,2.7,2.1,4.4c0,4.3-3.2,7.1-7.4,7.1C535.8,128.3,533.9,127.6,532.5,126.2z M543.1,122.3
		c0-3.7-2.5-6.4-5.4-6.4c-2.7,0-4.9,2.1-4.9,4.8c0,0.2,0,0.3,0,0.5c0,3.2,2.5,6.3,5.8,6.3C541.1,127.4,543.1,125.1,543.1,122.3z
		 M548.3,115.5c1,0,2.6,0,3.9-0.1c0.4,0.6,3.8,5,4.1,5.4c0.5,0.5,3.2,3.9,3.7,4.4c0-2,0-8.2-0.4-9.1c-0.3-0.1-1.3-0.2-1.7-0.3
		l-0.1-0.4c0.3,0,2.2,0.1,2.5,0.1s1.8,0,2.2-0.1l-0.1,0.4c-0.2,0-0.8,0.2-1.3,0.3c-0.2,1.2-0.3,2.5-0.3,3.7c0,0.8-0.2,8.2-0.3,8.9
		c-0.5-0.5-5.3-6.2-5.6-6.6c-0.2-0.3-3.5-4.4-4.1-5c0,0.4,0.2,9.6,0.4,10.3c0.3,0.1,1.5,0.2,1.9,0.2v0.4c-0.3,0-2.1-0.1-2.5-0.1
		s-1.8,0-2.3,0.1l0.1-0.4c0.5,0,1-0.1,1.4-0.2c0.3-0.7,0.5-10.6,0.5-11.2c-0.6-0.1-1.2-0.2-1.9-0.2L548.3,115.5z M583.3,127.6
		l1.9-0.2c0-0.7-1.5-8.6-1.7-9.9h-0.1c-0.5,1.1-3.8,9.5-4.3,10.5c-0.5-1.1-3.7-9.9-3.9-10.6h-0.1c-0.4,2.7-1.5,7.6-1.4,9.9
		c0.3,0.1,1.3,0.2,1.5,0.2l0.1,0.4c-0.2,0-2.2-0.1-2.5-0.1s-1.3,0-1.9,0.1l0.1-0.4c0.4-0.1,0.8-0.1,1.2-0.2
		c0.4-1.1,2.2-9.8,2.5-11.3c-0.5-0.1-1.5-0.2-1.7-0.3l-0.1-0.4c0.2,0,1.5,0.1,2.5,0.1c0.7,0,2.1,0,2.6-0.1l-0.1,0.4
		c-0.5,0.1-1,0.2-1.3,0.3c0.5,1.6,2.6,7.4,3.1,8.7c0.5-1,3.5-8.3,3.5-8.7c-0.4-0.1-0.8-0.2-1.2-0.3l-0.1-0.4c0.5,0,1.7,0.1,2.3,0.1
		s2,0,2.4-0.1v0.4c-0.2,0-1.1,0.2-1.4,0.3c0.3,2,2,10.1,2.2,11.3c0.4,0.1,1.1,0.2,1.4,0.2l0.1,0.4c-0.6,0-1.6-0.1-2.4-0.1
		s-2.7,0-3.2,0.1L583.3,127.6z M593.2,126.2c-1.1-1.1-1.7-2.7-1.6-4.3c0-3.5,2.8-6.7,7.5-6.7c1.7-0.1,3.4,0.4,4.8,1.4
		c1.3,1.1,2.1,2.7,2.1,4.4c0,4.3-3.2,7.1-7.4,7.1C596.5,128.3,594.6,127.6,593.2,126.2z M603.8,122.3c0-3.7-2.5-6.4-5.3-6.4
		c-2.7,0-4.9,2.1-4.9,4.8c0,0.2,0,0.3,0,0.5c0,3.2,2.5,6.3,5.8,6.3C601.8,127.4,603.8,125.1,603.8,122.3L603.8,122.3z M611,120.4
		c0-0.8,0-4-0.1-4.4c-0.5-0.1-1.4-0.2-1.8-0.3v-0.4c0.3,0,2.1,0.1,2.8,0.1s2.6-0.1,4-0.1c1.6-0.1,3.3,0.2,4.8,0.8
		c1.7,0.7,2.8,2.4,2.8,4.3c0,3.7-2.8,7.4-9.4,7.4h-5l0.1-0.4c0.6,0,1.2-0.1,1.8-0.2c0.1-0.3,0.1-3.4,0.1-5.5L611,120.4z M613,122
		c0,1,0,4.3,0.1,4.9c0.6,0.2,1.2,0.3,1.9,0.3c3.3,0,6.4-2,6.4-5.7c0.1-1.6-0.6-3.1-1.9-4.1c-1.5-0.9-3.2-1.4-4.9-1.3
		c-0.5,0-1,0-1.5,0.1c0,0.3-0.1,4.3-0.1,5.1V122z M628.7,121c0-1.6,0-4.4-0.1-4.9c-0.3-0.1-1.4-0.2-1.8-0.3l-0.1-0.4l1.5,0.1h1.5
		c0.5,0,4.6,0,5-0.1c0.1-0.3,0.1-0.6,0.1-0.8l0.4-0.1c-0.1,0.4-0.3,3.1-0.3,3.7l-0.4-0.1c0-0.1,0-0.9,0-1.3c-0.3-0.5-3-0.7-3.9-0.7
		c0,0.2-0.1,3.6-0.1,4.7v0.3c1.2,0,2.4-0.1,3.5-0.3c0.1-0.2,0.1-0.5,0.2-0.8h0.4c0,0.3-0.4,2.7-0.4,3.3l-0.3-0.2c0-0.4,0-0.8,0-1.2
		c-1.1-0.3-2.2-0.5-3.3-0.4v0.3c0,1.1,0.1,4.9,0.1,5.4c1.4,0.1,2.8-0.1,4.1-0.6c0.2-0.6,0.3-1.2,0.4-1.6l0.4-0.1
		c-0.1,0.5-0.5,3.2-0.6,4.1l-0.4-0.1c0-0.5,0-0.8,0-1.1c-0.5-0.1-4.3-0.2-5.4-0.2c-0.6,0-1.9,0-2.6,0.1l0.1-0.4
		c0.6,0,1.2-0.1,1.8-0.2c0.1-0.3,0.1-3.6,0.1-5.5L628.7,121z M641.3,120.4c0-0.6,0-4-0.1-4.3c-0.3-0.1-1.5-0.2-1.8-0.2v-0.4h3.7
		c1.6-0.1,3.3,0.1,4.8,0.5c0.9,0.3,1.5,1.1,1.5,2c-0.2,1.4-1.1,2.7-2.4,3.3c1.4,2.5,2.9,4.8,4.7,7l-0.7,0.1c-1.2-0.6-5.8-6-6-6.3
		c-0.7,0-1.3,0.1-1.6,0.1v0.5c0,1.1,0,3.8,0.1,4.7c0.6,0.1,1.2,0.2,1.8,0.2l0.1,0.4c-0.4,0-2.2-0.1-2.9-0.1s-2.5,0-2.9,0.1l0.1-0.4
		c0.6,0,1.2-0.1,1.8-0.2c0.1-0.5,0.1-4,0.1-5.8L641.3,120.4z M643.2,121.6c0.7,0,1.4-0.1,2.1-0.3c1.2-0.4,2-1.6,2-2.8
		c0-1.1-1-2.5-4-2.5c-0.1,0.7-0.1,3.9-0.1,5.1V121.6z M653.7,115.5c1,0,2.6,0,3.9-0.1c0.4,0.6,3.8,5,4.1,5.4
		c0.5,0.5,3.2,3.9,3.7,4.4c0-2,0-8.2-0.4-9.1c-0.3-0.1-1.2-0.2-1.7-0.3l-0.1-0.4c0.3,0,2.2,0.1,2.5,0.1s1.8,0,2.2-0.1l-0.1,0.4
		c-0.2,0-0.8,0.2-1.3,0.3c-0.2,1.2-0.3,2.5-0.3,3.7c0,0.8-0.2,8.2-0.3,8.9c-0.5-0.5-5.3-6.2-5.6-6.6c-0.2-0.3-3.5-4.4-4.1-5
		c0,0.4,0.2,9.6,0.4,10.3c0.3,0.1,1.5,0.2,1.9,0.2l0,0.4c-0.3,0-2.1-0.1-2.5-0.1s-1.8,0-2.3,0.1v-0.4c0.5,0,1-0.1,1.4-0.2
		c0.3-0.7,0.5-10.6,0.5-11.2c-0.6-0.1-1.3-0.2-1.9-0.2L653.7,115.5z M679,120.5c0-0.6,0-3.9-0.1-4.5c-0.3-0.1-1.4-0.2-1.8-0.3
		l-0.1-0.4c0.3,0,1.9,0.1,3,0.1s2.5,0,2.8-0.1l-0.1,0.4c-0.6,0.1-1.1,0.1-1.7,0.3c-0.1,0.5-0.1,2.6-0.1,4.1v0.8
		c0.4,0,6.4,0.1,7.1-0.1v-0.8c0-1.4,0-3.6-0.1-4.1c-0.6-0.1-1.1-0.2-1.7-0.3l-0.1-0.4c0.3,0,1.9,0.1,2.9,0.1s2.6,0,2.9-0.1l-0.1,0.4
		l-1.8,0.3c-0.1,0.3-0.1,4-0.1,4.5v1.2c0,2,0,5,0.2,5.6c0.3,0.1,1.4,0.2,1.7,0.2l0.1,0.4c-0.3,0-2.1-0.1-2.9-0.1s-2.3,0-2.9,0.1
		l0.1-0.4c0.3,0,1.4-0.2,1.7-0.2c0.1-0.6,0.1-2.8,0.1-4.7v-1c-0.8,0-6.9,0.3-7.1,0.4v0.6c0,1.8,0.1,4,0.1,4.7
		c0.5,0.1,1.1,0.2,1.7,0.2l0.1,0.4c-0.3,0-2-0.1-2.8-0.1s-2.5,0-3,0.1l0.1-0.4c0.4,0,1.5-0.2,1.8-0.2c0.1-0.8,0.1-3.8,0.1-5.1
		L679,120.5z M696.8,126.2c-1.1-1.1-1.7-2.7-1.6-4.3c0-3.5,2.8-6.7,7.5-6.7c1.7-0.1,3.4,0.4,4.7,1.4c1.3,1.1,2.1,2.7,2.1,4.4
		c0,4.3-3.2,7.1-7.4,7.1C700.2,128.3,698.2,127.6,696.8,126.2z M707.4,122.3c0-3.7-2.5-6.4-5.3-6.4c-2.7,0-4.9,2.1-4.9,4.8
		c0,0.2,0,0.3,0,0.5c0,3.2,2.5,6.3,5.8,6.3C705.4,127.4,707.4,125.1,707.4,122.3L707.4,122.3z M724.3,127.6l1.9-0.2
		c0-0.7-1.5-8.6-1.7-9.9h-0.1c-0.5,1.1-3.8,9.5-4.3,10.5c-0.5-1.1-3.7-9.9-3.9-10.6H716c-0.5,2.7-1.5,7.6-1.4,9.9
		c0.3,0.1,1.3,0.2,1.5,0.2l0.1,0.4c-0.2,0-2.2-0.1-2.5-0.1s-1.3,0-1.9,0.1l0.1-0.4c0.4-0.1,0.8-0.1,1.2-0.2
		c0.4-1.1,2.2-9.8,2.5-11.3c-0.5-0.1-1.5-0.2-1.7-0.3l-0.1-0.4c0.2,0,1.6,0.1,2.5,0.1c0.7,0,2.1,0,2.6-0.1l-0.1,0.4
		c-0.5,0.1-1,0.2-1.3,0.3c0.5,1.6,2.6,7.4,3.1,8.7c0.5-1,3.5-8.3,3.5-8.7c-0.4-0.1-0.8-0.2-1.2-0.3l-0.1-0.4c0.5,0,1.7,0.1,2.3,0.1
		s2,0,2.4-0.1l0,0.4c-0.2,0-1.1,0.2-1.4,0.3c0.3,2,2,10.1,2.2,11.3c0.4,0.1,1.1,0.2,1.4,0.2l0.1,0.4c-0.6,0-1.6-0.1-2.4-0.1
		s-2.7,0-3.2,0.1L724.3,127.6z M734.4,121c0-1.6,0-4.4-0.1-4.9c-0.3-0.1-1.4-0.2-1.8-0.3l-0.1-0.4l1.5,0.1h1.5c0.5,0,4.6,0,5-0.1
		c0.1-0.3,0.1-0.6,0.1-0.8l0.4-0.1c-0.1,0.4-0.3,3.1-0.3,3.7l-0.4-0.1c0-0.1,0-0.9,0-1.3c-0.3-0.5-3-0.7-3.8-0.7
		c0,0.2-0.1,3.6-0.1,4.7v0.3c1.2,0,2.4-0.1,3.5-0.3c0.1-0.2,0.1-0.5,0.2-0.8h0.4c0,0.3-0.4,2.7-0.5,3.3l-0.3-0.2c0-0.4,0-0.8,0-1.2
		c-1.1-0.3-2.2-0.5-3.3-0.4v0.3c0,1.1,0.1,4.9,0.1,5.4c1.4,0.1,2.8-0.1,4.1-0.6c0.2-0.6,0.3-1.2,0.4-1.6l0.4-0.1
		c-0.1,0.5-0.5,3.2-0.6,4.1l-0.4-0.1c0-0.5,0-0.8,0-1.1c-0.5-0.1-4.3-0.2-5.4-0.2c-0.6,0-1.9,0-2.6,0.1l0.1-0.4
		c0.6,0,1.2-0.1,1.8-0.2c0.1-0.3,0.1-3.6,0.1-5.5L734.4,121z"/>
	<path class="st1 t6" d="M296.7,310.6c-6.1-3.7-10.9-9.1-13.7-15.6c-3.1-6.9-4.7-15.1-4.7-24.8v-0.1c0-6,0.7-11.9,1.9-17.8
		c1.3-6.2,3.1-12.4,5.5-18.3c0.4-1,0.8-2.1,1.3-3.1s0.9-2,1.4-3l31.3-65.3h21.8l-36.8,75.7l0.1-3.8c1.6-3.2,4.1-5.9,7.2-7.6
		c3.4-1.9,7.2-2.8,11.1-2.7c7.5,0,13.9,1.8,19.2,5.3c5.6,3.8,9.8,9.2,12.2,15.5c2.8,6.8,4.2,14.9,4.2,24.5v0.1
		c0,9.8-1.6,18.1-4.7,25.1c-2.8,6.6-7.7,12.1-13.8,15.9c-6,3.6-13.3,5.4-21.7,5.4S302.7,314.2,296.7,310.6z M333.2,289.3
		c3.5-4.2,5.2-10.1,5.2-17.9v-0.1c0-8.5-1.8-15-5.5-19.7c-3.6-4.6-8.8-7-15.6-6.9c-6,0-10.7,2.3-14,7c-3.3,4.7-5,11.2-5,19.7v0.1
		c0,7.7,1.7,13.7,5.2,17.8s8.4,6.2,14.7,6.2S329.7,293.5,333.2,289.3z"/>
	<path class="st1 t6" d="M401,310.6c-6.1-3.7-10.9-9.1-13.7-15.6c-3.2-6.9-4.7-15.1-4.7-24.8v-0.1c0-6,0.7-11.9,1.9-17.8
		c1.3-6.2,3.1-12.4,5.5-18.3c0.4-1,0.8-2.1,1.3-3.1s0.9-2,1.4-3l31.3-65.4h21.8l-36.8,75.7l0.1-3.8c1.6-3.2,4.1-5.9,7.2-7.6
		c3.4-1.9,7.2-2.8,11.1-2.7c7.5,0,13.9,1.8,19.2,5.3c5.6,3.8,9.8,9.2,12.2,15.5c2.8,6.8,4.2,14.9,4.2,24.5v0.1
		c0,9.8-1.6,18.1-4.7,25.1c-2.8,6.6-7.6,12.1-13.8,15.9c-6,3.6-13.3,5.4-21.7,5.4C414.3,316,407,314.2,401,310.6z M437.6,289.3
		c3.5-4.2,5.2-10.1,5.2-17.9v-0.1c0-8.5-1.8-15-5.4-19.7c-3.6-4.6-8.8-7-15.6-6.9c-6,0-10.7,2.3-14,7c-3.3,4.7-5,11.2-5,19.7v0.1
		c0,7.7,1.7,13.7,5.2,17.8s8.4,6.2,14.7,6.2S434.1,293.5,437.6,289.3z"/>
		<path class="st0 t66" d="M604.9,320.5c-2.6-1.6-5.4-3-8.1-4.3c6.5-12.9,7.8-33.1,8.2-53.3l8.8,0.7c-0.1,5.5-0.4,10.8-0.7,15.6l0,0.2
			l0.1,0.2c2.5,7.8,5.5,13.3,9.7,17.4l1.7,1.7V252h-24.9v-8.4h22.6v-16h-19.4v-8.3h19.4v-13.1h9v13.1h17.5v8.3h-17.5v16h20.7v8.4
			h-18.4v18.5h17.2v8.2h-17.2v24.9l0.7,0.2c6.5,2.3,14.8,3.3,27.6,3.3c2.8,0,9.4,0.1,17.2,0.1c12.2,0,26.9-0.1,33.9-0.3
			c-1.6,3-2.9,6.2-3.7,9.5h-47.4c-23.6,0-39.2-3.1-48.7-18.9l-1.4-2.4l-0.4,2.8C609.7,307.1,607.7,314.5,604.9,320.5z M223.8,319.9
			c-1.3,0-2.7,0-4.1,0c-0.9-3.3-2.1-6.6-3.6-9.6c5.5,0.2,10.7,0.3,14.2,0.3c3.8,0,6.6-0.1,7.8-0.1c3.6-0.2,5-1.5,5-4.8v-62.5h-49.3
			v-9.7h49.3v-26.1h10.1v26.1h15v9.7h-15v62.5c0,8.4-2.4,10.8-6.7,12.3C242.3,319.3,235.7,319.9,223.8,319.9z M123.2,319.6v-6.5
			H60.4v6.5h-9.7v-75.9h32.8l0.2-0.8c0.9-3.1,1.9-6.6,2.5-9.8l0.2-1.2H37.2v-8.8h31.6l-1-1.5c-2.7-4.3-5.7-8.3-9-12.2l8.5-3.7
			c4.1,4,7.7,8.4,10.8,13.2l-8.2,4.3h35.8l0.3-0.4c3.8-5.3,7.3-10.9,10.3-16.7l11,2.5c-3,4.4-6.4,8.9-9.7,13l-1.3,1.6h31.1v8.8
			l-48.6,0l-0.6,1.4c-1.2,3.1-2.5,6.2-3.8,9l-0.7,1.4h39.8v75.9H123.2z M60.4,304.9l62.9,0v-13.3H60.4V304.9z M60.4,284.3h62.9
			v-12.7H60.4V284.3z M60.4,264.4h62.9v-12.5H60.4V264.4z M566.1,319.5c-0.6-2.5-1.5-5-2.5-7.4c2.1,0.1,4.1,0.1,5.6,0.1
			c1.6,0,2.7,0,3.4-0.1c2,0,2.8-0.8,2.8-2.8v-25h-35.7v13.4l15.5-0.8l-0.8-1.4c-1.1-1.9-2.3-3.9-3.7-6.1l6.7-1.7
			c4.3,6.2,9,14.7,10.6,20.9l-5.3,1.6l0,0l-2.1,0.7c-0.5-2-1.2-4.1-1.9-6l-0.3-0.7l-0.7,0c-11.1,0.7-22.5,1.4-32.5,2
			c-7,0.4-13.6,0.8-19.2,1.1l-0.7-8.1c3.7-0.1,7.8-0.3,12.2-0.5c3.8-0.2,7.9-0.3,12.2-0.5l1,0v-14h-34.1v35.2h-9.3v-35.1h-4.8v-7.6
			h4.8v-4h9.3v4h34.1v-8.6h-35.4v-36.8h81v36.8h-36.5v8.6h45v32.7c0,5.2-0.9,7.2-3.7,8.4C577.9,319.4,573.2,319.5,566.1,319.5z
			 M539.7,262.2h26.7v-9.6h-26.7V262.2z M504.4,262.1h26.2v-9.6h-26.2V262.1z M539.7,247h26.7v-9.5h-26.7V247z M504.4,247h26.2v-9.6
			h-26.2V247z M172.8,319.4v-68.2l-1.8,2.5c-3,4.2-6.4,8.2-10,11.9c-1.8-3.5-3.8-6.9-6-10.2c12.3-12,23.6-30,30.4-48.4l9.7,3.1
			c-3.5,8.7-7.6,17.3-12.3,25.5l-0.1,0.2v83.7H172.8z M675.5,298c-12.7,0-15.8-3.2-15.8-16.3v-70.3h45.4v44.3h-36v26
			c0,6.2,1.5,7.7,7.8,7.7h19.5c5.9,0,7-4.2,7.7-16.1c2.6,1.5,5.3,2.7,8.2,3.5c-1.5,16.9-4.7,21.2-15.1,21.2H675.5z M669,219.8v27.7
			h26.8v-27.8L669,219.8z M217.5,289.9c-2.8-6.2-8.3-15.9-16.3-29.1l8.4-3.9l0.2,0.3c5.9,9.2,13.1,20.5,16.9,27.8L217.5,289.9z
			 M552.3,227.5v-6.7h-13.9V213h13.9v-7.1h9.3v7.1h27.9v7.8h-27.9v6.7H552.3z M508.4,227.5v-6.7h-27.4V213h27.4v-7.1h9.3v7.1H532
			v7.8h-14.3v6.7H508.4z"/>
	<polyline class="st2 line" points="479.6,175.1 749.2,175.1 749.2,349.8 2.6,349.8 2.6,175.1 272.6,175.1 	"/>
</svg>-->
<img class="logo absolute" src="./s1/top-title.png">

    <!--   <img src="./s1/logo.png" alt="" class="logo absolute">
      <img src="./s1/t1.png" alt="" class="t1 absolute">
      <img src="./s1/t2.png" alt="" class="t2 absolute">  -->
    </div>
  </div>
</template>

<style lang="scss" scoped>

	.st0{fill:#FFFFFF00;}
  .logo1{stroke:#fff;animation: letterDraw 2s linear .6s forwards;stroke-width:2;
    stroke-dasharray: 780;
    stroke-dashoffset: 780;}
	.logo_l{animation: op 1s linear 1.6s forwards;}
  .t6{stroke:#EEE93A;animation: letterDraw2 1.5s linear 2.3s forwards;fill:#EEE93A00;stroke-width:3.48;stroke-miterlimit:10;
    stroke-dasharray: 460;
    stroke-dashoffset: 460;}
  .t66{stroke:#fff;animation: letterDraw 1.8s linear 2.3s forwards;stroke-width:2;
    stroke-dasharray: 800;
    stroke-dashoffset: 800;}
  .line{fill:none;stroke:#FFFFFF;stroke-width:4;stroke-miterlimit:10;animation: letterDraw3 1s linear 3s forwards;
    stroke-dasharray: 1640;
    stroke-dashoffset: 1640;}
   .tt2{stroke:#fff;animation: letterDraw 1s linear 3.2s forwards;stroke-width:2;
    stroke-dasharray: 420;
    stroke-dashoffset: 420;}
	
	
@keyframes letterDraw{
    50%{
        stroke-dashoffset: 0;fill:#FFFFFF00;
    }
    to {
        stroke-dashoffset: 0;fill:#FFFFFF;
    }
}
@keyframes letterDraw3{
    50%{
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes letterDraw2{
    50%{
        stroke-dashoffset: 0;fill:#EEE93A00;
    }
    to {
        stroke-dashoffset: 0;fill:#EEE93A;
    }
}
@keyframes op {
    to {
       fill:#FFFFFF;
    }
}

@import '@/assets/style/function.scss';

.section1 {
  width: size(1920);
  height: 100vh;
  min-height: size(900);
  max-height: size(1080);
  overflow: hidden;
  position: relative;
  // background: url("./s1/bg.jpg") fixed ;
  background-size: auto;
}

.bg-img {
  width: size(1920);
  height: 100%;
  min-height: size(900);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  object-fit: cover;
  margin-top: 0;

  &:nth-child(1) {
    position: relative;
  }
}

.logo {
  width: size(743);
  top: calc(50% - 9.5vw);
  left: size(589);
  filter: drop-shadow(0px 10px 5px #044955)
}
/*
.t1 {
  width: size(751);
  top: size(483);
  left: size(585);
}

.t2 {
  width: size(751);
  top: size(711);
  left: size(585);
}
*/
@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    width: 100vw;
    min-height: sizem(470);
    max-height: sizem(812);
    height: calc(100vh - 63px);
    // background-image: url('./mo/1/bg.png');
    background-size: cover;
    background-attachment: scroll;
  }
  .logo {
    width: sizem(308);
    top: sizem(243);
    left: sizem(35);
  }

  .t1 {
    width: sizem(308);
    top: sizem(309);
    left: sizem(35);
  }

  .t2 {
    width: sizem(308);
    top: sizem(403);
    left: sizem(35);
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'

export default {
  name: 'section1',

  data() {
    return {
      isPC,
      isMobile,
      isTablet,
    }
  },

  methods: {},

  mounted() {},

  created() {},

  computed: {},
}
</script>
